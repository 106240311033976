<template>
  <v-footer
    class="pa-0 ma-0 pt-14 mt-5"
    dark
    style="position: relative;backgroundColor:#0D2E45"
  >
    <Loading v-if="settingsGetter && settingsGetter.settingsLoading" />
    <template
      v-if="settingsGetter && !settingsGetter.settingsLoading && settingsGetter"
    >
      <base-btn
        title="Go to top"
        @click="$vuetify.goTo(0)"
        style="position:absolute;top: 50px;left: 50px"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </base-btn>

      <Container>
        <v-row justify="center">
          <v-col cols="6" md="4">
            <div>
              <!-- <div class="text-h4 white--text mb-4 text-center">
              من نحن
            </div> -->
              <div class="grey--text body-1 text-center">
                {{
                  settingsGetter[
                    $root.lang == 'ar' ? 'who_are_we' : 'enwho_are_we'
                  ]
                }}
                <!-- مركز استشاري وتدريبي يهدف الى رفع مستوى الوعي الذاتي للأفراد
              والمؤسسات من خلال تقديم فهم عميق للذات وللآخرين، وتقديم خريطة
              للمواهب والمزايا من أجل رسم مسار صحيح للتطوير . -->
              </div>
              <v-row justify="center" class="mt-6">
                <v-img
                  alt="4talents Logo"
                  class="shrink mr-2"
                  contain
                  :src="require('../../assets/images/4talents.png')"
                  transition="scale-transition"
                  width="70"
                />
              </v-row>
              <!-- <base-btn
              v-for="(item, i) in items"
              :key="i"
              :href="item.href"
              color="white"
              class="ml-3 mr-0"
              target="_blank"
              style="borderColor: white"
              outlined
            >
              <v-icon color="white" v-text="item.icon" />
            </base-btn> -->
            </div>
          </v-col>

          <v-col cols="12" md="4" class="text-center">
            <!-- <h4 class="text-h4 white--text mb-4">{{ $t('contactUs') }}</h4> -->
            <div>
              <div class="text-h5 white--text mb-2">
                {{ $t('istanbulTurkey') }}
              </div>
              <div class="grey--text body-1">{{ settingsGetter.phone1 }}</div>
              <!-- <div class="grey--text body-1">{{ settingsGetter.email }}</div> -->
              <!-- <div class="body-1 grey--text">info@4talents.academy</div> -->
            </div>
            <div class="white my-3" style="width: 50%" />
            <div>
              <div class="text-h5 white--text mb-2">{{ $t('omanJordan') }}</div>
              <div class="grey--text body-1">{{ settingsGetter.phone2 }}</div>
              <!-- <div class="grey--text body-1">{{ settingsGetter.email }}</div> -->
            </div>
            <div class="white my-3" style="width: 50%" />
            <div>
              <div class="text-h5 white--text mb-2">{{ $t('email') }}</div>
              <div class="grey--text body-1">{{ settingsGetter.email }}</div>
            </div>
          </v-col>

          <v-col cols="12" md="4" class="text-center">
            <div v-for="(link, i) in links" :key="i" @click="navigate(link.to)">
              <!-- :to="link.to" -->
              <div
                style="cursor:pointer;display:inline-block"
                class="grey--text body-1"
              >
                {{ $t('NAV.' + link.text) }}
              </div>
            </div>
            <!-- <router-link
              v-for="(link, i) in links"
              :key="i"
              :to="link.to"
              class="text-decoration-none"
            >
              <div class="grey--text body-1">{{ $t('NAV.' + link.text) }}</div>
            </router-link> -->
          </v-col>
        </v-row>
      </Container>
      <div class="py-6 mt-14" style="width: 100%;backgroundColor: rgb(3 41 64)">
        <Container>
          <!-- <v-divider class="white my-8" /> -->
          <v-row align="center">
            <div class="body-1 white--text">
              4talents Academy © Copyright 2020
            </div>
            <v-spacer></v-spacer>
            <base-btn
              v-for="(item, i) in items"
              :href="settingsGetter[item.href]"
              :key="i"
              color="white"
              class="ml-3 mr-0"
              target="_blank"
              style="borderColor: white;width: 30px;height: 30px"
              outlined
            >
              <v-icon color="white" style="fontSize: 15px" v-text="item.icon" />
            </base-btn>
          </v-row>
        </Container>
      </div>
    </template>
    <!-- <v-divider class="white my-5" /> -->
    <!-- <Container>
      <div class="body-1 white--text text-center">
        4talents Academy © Copyright 2020
      </div>
    </Container> -->
  </v-footer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import Container from '../base/Container.vue';
export default {
  components: {
    Loading: () => import('../Loading.vue'),
  },
  name: 'CoreFooter',

  data: () => ({
    items: [
      {
        href: 'twitter',
        icon: 'mdi-twitter',
      },
      {
        href: 'instagram',
        icon: 'mdi-instagram',
      },
      {
        href: 'facebook',
        icon: 'mdi-facebook',
      },
      {
        href: 'youtube',
        icon: 'mdi-youtube',
      },
      {
        href: 'telegram',
        icon: 'mdi-send-circle',
      },
    ],
  }),
  methods: {
    ...mapActions(['getSettingsAction']),
    print(href) {
      console.log(this.settingsGetter[href]);
    },
    navigate(to) {
      this.$router.push(to).catch(() => {});
      setTimeout(() => {
        this.$vuetify.goTo(0);
      }, 200);
    },
  },
  mounted() {
    this.getSettingsAction();
  },
  computed: {
    ...mapGetters(['links', 'settingsGetter']),
  },
};
</script>
